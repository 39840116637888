var ismobile = '23';
var $window;
var $header;
var $teaser;
var $navbar;
var fadeableContent;
var fadeableFooter;
var $teasertext;
var $teasertextContainer;
var menueMaxScrollHoehe = 30;
var $embedResponsive;
var $logo;
var $realLogo;
var md;
var $banner;
var $fbIcon;
var intersectionObserver;
var slideIndex = 1;
var i;

$(document).ready(function () {
    md = new MobileDetect(window.navigator.userAgent);
    $window = $(window);
    $header = $('header');
    $teaser = $('.main__teaser__tease');
    $logo = $header.find('.logo');
    $realLogo = $logo.find('img');
    $embedResponsive = $teaser.find('.embed-responsive');
    $banner = $('.banner');
    $fbIcon = $header.find('.facebook-link');
    $teasertextContainer = $('.teaser-text--container');

    $navbar = $header.find('.navbar');
    $teasertext = $teaser.find('.teaser-text');

    initOnepager();
    windowResize();

    if(md.mobile()) {
        $('.grauebox').first().addClass('active');
        $('.dot').first().addClass('active');

        $('.dot').off('click').on('click', function() {
            var nummer = $(this).data('id');
            $('.activedot').removeClass('activedot');
            $(this).addClass('activedot');
            $('.grauebox').each(function(index) {
                if((index + 1) == nummer) {
                    $('.active').removeClass('active');
                    $(this).addClass('active');
                }
            });
        });
    }

    $window.on('resize', function () {
        windowResize();
        slideIndex = 1;
    });

    $window.trigger("resize");
    $window.on('scroll', function (e) {
        if (!ismobile) animateMenue(e);
        fadeContent();
    });

    initContent();
    adjustAnsprechpartner();
});

function initOnepager() {
    if(document.readyState != "complete") {
        window.setTimeout(initOnepager, 100);
        return false;
    } else {
        onepager.variables.callback = function () {
            initContent();
        };
    }
}

function initContent() {
    if(window.IntersectionObserver) {
        intersectionObserver = new IntersectionObserver(function(event) {
            event.forEach(function(entry) {
                if(entry.isIntersecting === true) {
                    entry.target.classList.add('fadeContentIn');
                    intersectionObserver.unobserve(entry.target);
                }
            });
        });
    }
    initFadeTeaser();
    initFadeContent();
    initFlexsliderTeaser();

    initScrollTop();

    if(ismobile) {
        initMobileFunctions();
    }

    if(!intersectionObserver) {
        $window.off('scroll').on('scroll', function (e) {
            fadeContent();
        });
    }
    fadeContent();
}

function lazyLoad() {
    fitNavspaltenHeaders();
    $(window).trigger('scroll');
}

function initFadeContent() {
    fadeableContent = $('.main__content__ajax').find('>.container, >.container-fluid').find('>*').not('.no-fade');
    fadeableFooter = $('#footer').find('>div').not('.no-fade');

    if(!intersectionObserver) {
        $.each(fadeableContent, function (i, v) {
            $(v).data().top = $(v).offset().top;
        });

        $.each(fadeableFooter, function (i, v) {
            $(v).data().top = $(v).offset().top;
        });
    } else {
        $.each(fadeableContent, function(i, v) {
            intersectionObserver.observe(v);
        });
        $.each(fadeableFooter, function(i, v) {
            intersectionObserver.observe(v);
        });
    }
}

function fadeContent() {
    var offset = $window.scrollTop() + $window.height() + 200;
    $.each(fadeableContent, function (i, v) {
        if ($(v).data().top < offset) {
            $(v).addClass('fadeContentIn');
        }
    });
    if (fadeableFooter.data().top < offset) {
        fadeableFooter.addClass('fadeContentIn');
    }
}

function windowResize() {
    if (md.mobile()) {
        if (!ismobile || ismobile === '23') {
            ismobile = true;
            $('.backgroundvideo').html('');
            $('.header').css('transform', '');
        }
    } else {
        if (ismobile || ismobile === '23') {
            ismobile = false;
        }
    }
}

function animateMenue(e) {
    if (ismobile) return;
    var scrollhoehe = e.currentTarget.scrollY;

    var headerOffset = scrollhoehe / 4;

    var m = scrollhoehe / 6;
    var bannerHeight = scrollhoehe / 3;
    if(bannerHeight > 500) bannerHeight = 500;
    // var scale = 1*(scrollhoehe/30);
    // if(scale < 1) scale = 1;
    // if(scale > 10) scale = 10;

    if(headerOffset > menueMaxScrollHoehe) headerOffset = menueMaxScrollHoehe;
    $header.css('transform','translateY(-'+headerOffset+'px)');


    var logoHeight = 83 - headerOffset;

    if(logoHeight < 40) logoHeight = 40;
    if(logoHeight > 83) logoHeight = 83;

    $logo.css('height', logoHeight + 'px');
    $realLogo.css('height', logoHeight + 'px');

    var fbIconTop = 15 + headerOffset;
    if(fbIconTop > 70) fbIconTop = 70;
    $fbIcon.css('top', fbIconTop + 'px');

    var navbarTranslateTop = scrollhoehe / 8;
    if(navbarTranslateTop > 23) navbarTranslateTop = 23;
    $navbar.css('transform', 'translateY(-'+navbarTranslateTop+'px)');
}

function initKontaktformular() {
    var $frmContact = $('#frmContact');
    var $submitBtn = $('#submitBtn');
    $frmContact.submit(function () {
        var l = Ladda.create(document.querySelector('#submitBtn'));
        l.start();
        $.ajax({
            type: 'POST',
            url: window.location.href,
            data: $frmContact.serialize(),
        }).done().then(function (msg) {
            l.stop();
            msg = JSON.parse(msg);
            var tmp = $submitBtn.parent().html();
            $submitBtn.parent().html('<div class="alert alert-' + msg.status + ' text-left fade in">' + msg.text + '</div>');
            if (msg.status === 'success')
                $frmContact[0].reset();
            setTimeout(function () {
                $('.alert').parent().fadeOut(300, function () {
                    $('.alert').parent().html(tmp);
                    $submitBtn.parent().fadeIn(300);
                });
            }, 1500);
        });

        return false;
    });
}

function initMobileFunctions() {
    $('.mobilemenu__button').off('click').on('click', function () {
        $('.mobilemenu').addClass('open');
    });
    $('.mobilemenu__button-close, .mobilemenu').off('click').on('click', function () {
        $('.mobilemenu').removeClass('open');
        $('.mobilemenu__button-close').css('position', 'absolute');
    });
    $('.mobilemenu').on('transitionend', function (e) {
        if(parseInt(getComputedStyle(e.target).right) == 0) {
            $(e.target).find('.mobilemenu__button-close').css('position', 'fixed');
        } else {
            $('.mobilemenu').scrollTop(0);
        }
    });
}

function initFadeTeaser() {
    $teasertext.addClass('show');
    $teasertext.find('span').addClass('show');
}

function fitNavspaltenHeaders() {
    $('.navspalten--listing').each(function(i, row) {
        var maxHeight = 0;
        var $navspalten = $(row).find('.navspalten').find('.navspalten--headline');
        $navspalten.each(function(x, ele) {
            var height = parseInt($(ele).css('height'));
            if(maxHeight < height) maxHeight = height;
        });
        $navspalten.css('height',maxHeight+'px');
    });
}

function initScrollTop() {
    $('.scrollTop').off('click').on('click', function() {
        $window.scrollTop(0);
    });
}

var disabledLink = function(e) {
    e.preventDefault();
    e.stopPropagation();
}

function adjustAnsprechpartner() {
    var $rows = $('.persoenliche-beratung--listing');
    if($rows.length == 0) {
        return;
    }
    $rows.each(function(i, v) {
        var $row = $(v);
        var $entries = $row.find('.persoenliche-beratung--entry');
        var maxHeightH3 = [];
        var maxHeightH4 = [];
        var tmpHeightH3 = 0;
        var tmpHeightH4 = 0;
        for(var i = 0; i < $entries.length; i++) {
            var $e = $($entries[i]);
            var row = $e.attr('data-row');
            tmpHeightH3 = $e.find('h3').height();
            if(!maxHeightH3[row] || tmpHeightH3 > maxHeightH3[row]) {
                maxHeightH3[row] = tmpHeightH3;
            }
            tmpHeightH4 = $e.find('h4').height();
            if(!maxHeightH4[row] || tmpHeightH4 > maxHeightH4[row]) {
                maxHeightH4[row] = tmpHeightH4;
            }
        }
        for (var i = 0; i < maxHeightH3.length; i++) {
            var $e = $row.find('.persoenliche-beratung--entry[data-row='+i+']');
            $e.find('h3').css('height', maxHeightH3[i] + 'px');
            $e.find('h4').css('height', maxHeightH4[i] + 'px');
        }
        console.log(maxHeightH3, maxHeightH4);
    });
}

